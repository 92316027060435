.policy-banner {
  background-color: var(--primary);
  color: #FFFFFF;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 3rem;
  align-items: center;
  justify-content: center;
}

.policy-banner a{
  text-decoration: underline;
  color: #FFFFFF;
}

.policy-banner button {
  border-radius: 6px;
  border-style: none;
  border-width: 0;
  outline: none;
  cursor: pointer;
  -webkit-appearance: button;
  padding: 0.5rem 0.8rem;
  background-color: var(--secondary);
  color: var(--primary);
  font-family: "Roboto";
  font-weight: bold;
  margin-left: 1rem;
}

.policy-banner button:enabled:active {
  transform: scale(0.9);
}

.hidden {
  display: none;
}