.pdb-section-title {
  color: var(--primary);
  font-family: "Montserrat";
  font-weight: bold;
  text-align: left;
  font-size: 1.25rem;
  margin: 0 0 0.75rem 0;
  align-self: flex-start;
}

.protein-section {
  width: 100%;
  margin: 1.5rem 0;
}

.protein-grid {
  align-self: flex-start;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.grid-element {
  padding: 0.5rem;
}

.grid-header {
  padding: 0.5rem;
  background-color: var(--secondary);
}

.grid-title {
  margin: 0;
  color: var(--primary);
  font-family: "Roboto";
  font-weight: bold;
  text-align: left;
  font-size: 1rem;
}

.grid-text {
  margin: 0;
  color: var(--primary);
  font-family: "Roboto";
}

.switch {
  position: relative;
  display: inline-block;
  margin-left: 0.8rem;
}

.switch-input {
  display: none;
}

.switch-label {
  display: block;
  width: 36px;
  height: 20px;
  text-indent: -150%;
  clip: rect(0 0 0 0);
  color: transparent;
  user-select: none;
}

.switch-label::before,
.switch-label::after {
  content: "";
  display: block;
  position: absolute;
  cursor: pointer;
}

.switch-label::before {
  width: 36px;
  height: 20px;
  background-color: #dedede;
  border-radius: 9999em;
  -webkit-transition: background-color 0.1s ease;
  transition: background-color 0.1s ease;
}

.switch-label::after {
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--primary);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.45);
  -webkit-transition: left 0.1s ease;
  transition: left 0.1s ease;
}

.switch-input:checked + .switch-label::before {
  background-color: var(--secondary);
}

.switch-input:checked + .switch-label::after {
  left: 18px;
}