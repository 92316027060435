.app-container {
  width: 100vw;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 1rem 0;
}

.input-card {
  width: 35rem;
  /* height: 350px; */
  /* background-color: #FFFFFF; */
  /* box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15); */
  border-radius: 16px;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.input-card-bottom {
  justify-content: center;
  align-items: center;
  /* width: 350px; */
  /* height: 195px; */
}

.text-basic {
  text-align: justify;
  text-justify: inter-word;
}

.description {
  margin-bottom: 1.5rem;
}

.input-img {
  width: 100px;
  margin: 0 0 1rem 0;
}

.search-container {
  justify-content: space-between;
}

.search-input {
  margin: 0 0.75rem;
  padding: 8px 4px;
  border: 3px solid var(--primary);
  border-radius: 10px;
  resize: none;
  outline: none;
  min-width: 150px;
  max-width: 200px;
  font-family: Roboto;
  color: var(--primary);
  font-size: 14px;
}

.search-input-box {
  margin-right: 2rem;
}

.pdb-text {
  margin: 1.5rem 0;
  padding: 0.5rem;
  border: 3px solid var(--primary);
  border-radius: 10px;
  resize: none;
  outline: none;
  width: 100%;
  font-family: Roboto;
  color: black;
  font-size: 14px;
  box-sizing: border-box;
}

.pdb-text::placeholder,
.text-input::placeholder {
  color: var(--primarylight);
  font-size: 14px;
  font-family: Roboto;
}

.pdb-text:focus,
.search-input:focus {
  box-shadow: 0px 0px 6px 1px var(--primary);
}

.align-left {
  text-align: left;
  width: 100%;
  margin-top: 0;
}

.preprint-link {
  text-decoration: underline;
  color: var(--lightgray);
}

@media screen and (max-width: 650px) {
  .input-card {
    width: 85vw;
  }

  .search-container {
    flex-direction: column;
  }
  
  .search-input-box {
    margin-right: 0;
  }

  .search-input {
    width: 50%;
    margin-left: 0;
  }

  .upload-btn {
    width: 120px;
    align-self: center;
    margin-top: 1rem;
  }
}

@media screen and (max-width: 400px) {
  .search-input-box {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .search-input {
    width: 100%;
    margin: 0 0 0.5rem 0;
  }
}

.small-dropdown {
  font-size: 14px; /* Adjust the font size */
  padding: 6px 8px; /* Adjust the padding */
  width: 120px; /* Adjust the width */
  border: 1px solid var(--primary); /* Add border */
  border-radius: 6px; /* Add border radius */
  outline: none; /* Remove outline */
  background-color: white; /* Set background color */
  margin-left: 22px;
  color: var(--primary); /* Set text color */
}

/* Style for dropdown arrow */
.small-dropdown:after {
  content: "\25BC";  /* Unicode character for dropdown arrow */
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

/* Style for dropdown arrow - for IE 9 and earlier */
.small-dropdown:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 6px;
  margin-top: -3px;
  border-width: 6px 6px 0;
  border-color: var(--primary) transparent transparent transparent;
  border-style: solid;
}
